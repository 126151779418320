import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ClientsSection.scss';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import { screenSizes } from '../../../utils/consts';
import { isSmallerThanWindowSize } from '../../../utils/windowSize';
import DecoHeader from '../../../core-ui/DecoHeader/DecHeader';

function ClientsSection() {
  const { t } = useTranslation()
  const [isSmallSize, setIsSmallSize] = useState(isSmallerThanWindowSize(screenSizes.md));

  useEffect(() => {
    function windowResize() {
      setIsSmallSize(isSmallerThanWindowSize(screenSizes.md))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])
  
  function renderSmallSwiper() {
    const images = Array.from(Array(7).keys()).map(i => `car${i + 1}.png`)

    return (

        <Swiper 
        className='pt-4 px-2 clients-section-swiper--sm'
        modules={[Autoplay, Navigation]}
        slidesPerView={2}
        autoplay={{
          delay: 2000,
        }}
        speed={1000}
        loop={true}>
      {
        images.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={`/images/clients/${img}`} alt={img}/>
            </SwiperSlide>
          )
        })
      }
      </Swiper>
    )
  }

  function renderRegSiwper() {
    const images = Array.from(Array(17).keys()).map(i => `client${i + 1}.png`)

    return (
      <Swiper
        aria-live="polite"
        className='pt-4'
        modules={[Autoplay, Navigation]}
        slidesPerView='auto'
        freeMode={true}
        speed={3000}
        spaceBetween={50}
        autoplay={{
          delay: 1,
        }}
        loop={true}>
      {
        images.map((img, index) => {
          return (
            <SwiperSlide className='clients-section-swiper-slide' key={index}>
              <img src={`/images/clients/${img}`} alt={img}/>
            </SwiperSlide>
          )
        })
      }
      </Swiper>
    )
  }

  return (
    <div className='clients-section'>
      <DecoHeader text={ t('home.clients.header') } />
      { isSmallSize ? renderSmallSwiper() : renderRegSiwper() }
    </div>
  )

}

export default ClientsSection;
