import React from 'react';
import './PropsPage.scss';
import { useTranslation } from 'react-i18next';
import ImageCardMask from '../../core-ui/imageCard/ImageCardMask';
import ImageCardContent from '../../core-ui/imageCard/ImageCardContent';
import ImageCardImg from '../../core-ui/imageCard/ImageCardImg';
import ImageCard from '../../core-ui/imageCard/ImageCard';
import { Paths } from '../../routing/paths';
import ImageCardMaskTitle from '../../core-ui/imageCard/ImageCardTitle';
import { useTitle } from '../../utils/useTitle';

function PropsPage() {
  useTitle("Properties")
  const { t } = useTranslation()

  return (
    <div className='container props-page'>
      <h1>{ t("props-page.header") }</h1>
      <div className='gallery props-page-gallery'>
        <figure className='props-page-gallery-item--1'>
          <ImageCard link={Paths.properties.palmahim}>
            <ImageCardImg src='images/props/pal-card.jpg' alt='palmahim'/>
            <ImageCardMaskTitle title={ t("props-page.pal.title") } displayOnlyOnHover={true}/>
            <ImageCardMask />
            <ImageCardContent title={ t("props-page.pal.title").toUpperCase() } 
              content={ t("props-page.pal.content") }/>
          </ImageCard>
        </figure>
        <figure className='props-page-gallery-item--2'>
          <ImageCard link={Paths.properties.airpark}>
            <ImageCardImg src='images/props/airpark-card.jpg' alt='airpark'/>
            <ImageCardMaskTitle title={ t("props-page.airpark.title") } displayOnlyOnHover={true}/>
            <ImageCardMask/>
            <ImageCardContent title={ t("props-page.airpark.title").toUpperCase() } 
              content={ t("props-page.airpark.content") }/>
          </ImageCard>
        </figure>
      </div>
    </div>
  );
}

export default PropsPage;
