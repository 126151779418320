import React from 'react';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../utils/scroll';
import './ArrowLink.scss';

export type BackArrowLinkProps = {
    txt: string,
    link: string,
    className?: string
    color?: string
}

function BackArrowLink(props: BackArrowLinkProps) {
  const color = props.color ?? 'var(--ies-primary)'

  return (
    <Link to={props.link ?? "#"} 
      className={'back-arrow-link '.concat(props.className ?? "")}
      onClick={scrollToTop}>
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.7852 10.5H5.21373" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
          <path d="M7.35742 8.35742L5.21456 10.5003L7.35742 12.6431" stroke={color} strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <p style={{color: color}}>
          { props.txt }
        </p>
    </Link>
  )
}

export default BackArrowLink;