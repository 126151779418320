import React from 'react';

type IconDetailedProp = {
  img: string,
  text: string,
  fontWeight?: number
}

function IconDetailed(props: IconDetailedProp) {
  return (
    <div>
        <img src={props.img} alt=""/>
        <span style={{fontWeight: props.fontWeight}} className='ps-2'>{props.text}</span>
    </div>
  )
}

export default IconDetailed;