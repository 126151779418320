import React from 'react';
import { useNavigate } from 'react-router-dom';

export type ImageCardImgProps = {
  children: JSX.Element[]
  link?: string
}

function ImageCard(props: ImageCardImgProps) {
  const navigate = useNavigate()
  function navToLink() {
    if(props.link) {
      navigate(props.link)
    }
  }

  return (
    <div onClick={navToLink} tabIndex={props.link? 0 : undefined} className='img-card'>
      { props.children }
    </div>
  )
}

export default ImageCard;