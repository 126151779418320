import React from 'react';

type OfficeMailProps = {
  fontWeight?: number
}

function OfficeMail(props: OfficeMailProps) {
  return (
    <div>
      <img src='/icons/mail-white.svg' alt='mail icon'/>
      <a href='mailto:office@ies.com' target="_blank" rel="noopener noreferrer" style={{fontWeight: props.fontWeight}} className='ps-2 a-href-link'>office@ies.com</a>
    </div>
  )
}

export default OfficeMail;