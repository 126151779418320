import React, { useEffect, useState } from 'react';
import './ContactUsPage.scss';
import { useTranslation } from 'react-i18next';
import OfficeMail from '../misc/Mail';
import Phone from '../misc/Phone';
import { screenSizes } from '../../utils/consts';
import { isBiggerThanWindowSize, isSmallerThanWindowSize } from '../../utils/windowSize';
import { useTitle } from '../../utils/useTitle';
import LazyLoadBgImg from '../../core-ui/LazyLoadBgImg/LazyLoadBgImg';

function ContactUsPage() {
  useTitle("Contact us")
  const { t } = useTranslation()
  const [img, setImg] = useState<string>('')

  useEffect(() => {

    function windowResize() {
      const isSmall = isSmallerThanWindowSize(screenSizes.md)
      const isXl = isBiggerThanWindowSize(screenSizes.xl)

      if(isSmall) {
        setImg('/images/contactUs/contact-us-bg-sm.png')
      } else if (isXl) {
        setImg('/images/contactUs/contact-us-bg.png')
      } else {
        setImg('/images/contactUs/contact-us-bg-md.png')
      }
    }

    windowResize()
    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  return (
    <div className='contact-us-page'>
      <div className='contact-us-content'>
        <h1>{ t('contact-us.header') }</h1>
        <div className='mb-3 mt-4'>
          <p style={{fontWeight: 600}}>{ t('contact-us.sub-header') }</p>
          <p className='mt-2'>
            { t('contact-us.street') }<br/>
            { t('contact-us.city') }
          </p>
        </div>
        <div className='contact-us-icons'>
          <OfficeMail fontWeight={600}/>
          <Phone fontWeight={600}/>
        </div>
      </div>
      <LazyLoadBgImg src={img} center={true} placeholder='/images/contactUs/contact-us-bg-px.jpg'/>
    </div>
  )
}

export default ContactUsPage;