import React, { useEffect, useState } from 'react';
import './TeamPage.scss';
import { useTranslation } from 'react-i18next';
import { isSmallerThanSize, isSmallerThanWindowSize } from '../../utils/windowSize';
import { screenSizes } from '../../utils/consts';
import { useTitle } from '../../utils/useTitle';
import LazyLoadBgImg from '../../core-ui/LazyLoadBgImg/LazyLoadBgImg';

function TeamPage() {
  useTitle("Team")
  const xsWindowSize = 500
  const { t } = useTranslation()
  const [isSmallSize, setIsSmallSize] = useState(isSmallerThanWindowSize(screenSizes.md));
  const [isXSSmallSize, setIsXSSmallSize] = useState(isSmallerThanSize(xsWindowSize));

  useEffect(() => {

    function windowResize() {
      setIsSmallSize(isSmallerThanWindowSize(screenSizes.md))
      setIsXSSmallSize(isSmallerThanSize(xsWindowSize))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  function renderXS() {
    const p1 = [t("team.p1-l1"), t("team.p1-l2")].join(' ')
    const p2 = [t("team.p2-l1"), t("team.p2-l2"), t("team.p2-l3")].join(' ')

    return (
      <>
      <h2>{ t("team.header") }</h2>
      <p>{ p1 }</p>
      <p className='mb-3'/>
      <p>{ p2 }</p>
    </>
    )
  }

  function renderBiggerEqSmall() {
    return (
      <>
        <h2>{ t("team.header") }</h2>
        <p>{ t("team.p1-l1") }</p>
        <p>{ t("team.p1-l2") }</p>
        <p className='mb-3'/>
        <p>{ t("team.p2-l1") }</p>
        <p>{ t("team.p2-l2") }</p>
        <p>{ t("team.p2-l3") }</p>
      </>
    )
  }

  return (
    <div className='team-page'>
      { isSmallSize ? 
      <div className='team-page-bg-small'> 
        <LazyLoadBgImg 
          src='/images/team/team-md.jpg'
          placeholder='/images/team/team-px.jpg' 
          center={true} />
      </div> : undefined }
      <div className='container team-page-content'>
        {isXSSmallSize ?
          renderXS():
          renderBiggerEqSmall()
        }
      </div>
      { isSmallSize ? undefined: 
      <div className='team-page-bg-regular'> 
        <LazyLoadBgImg 
          src='/images/team/team-xl.jpg'
          placeholder='/images/team/team-px.jpg' 
          center={true} />
      </div> }
    </div>
  )
}

export default TeamPage;