import React from 'react';

export type ImageCardMaskTitleProps = {
  title: string
  displayOnlyOnHover?: boolean
  splitNewLine?: boolean
}

function ImageCardMaskTitle(props: ImageCardMaskTitleProps) {
  const text = props.splitNewLine ?
    props.title.split('\n') :
    [props.title]
  
  const body = text.map((text, index) => <h2 key={index}>{ text }</h2>)
  return (
    <div className="img-card-mask-title" data-displayonlyonhover={props.displayOnlyOnHover ?? false}>
      { body }
    </div>
)
}

export default ImageCardMaskTitle;