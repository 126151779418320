import React, { useEffect, useState } from 'react';
import './PropPage.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Pagination, Navigation, EffectCreative } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { t } from 'i18next';
import BackArrowLink from '../../../core-ui/arrowLink/BackArrowLink';
import { Paths } from '../../../routing/paths';
import { screenSizes } from '../../../utils/consts';
import { isBiggerThanWindowSize, isSmallerThanWindowSize } from '../../../utils/windowSize';

import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import { useTitle } from '../../../utils/useTitle';

type ProperyProps = {
  descTitle: string,
  subTitle: string,
  descText: string[],
  imgsXl: string[],
  imgsMd: string[],
  imgsSm: string[],
  imgsPh: string[],
}

function PropPage(props: ProperyProps) {
  useTitle(`${props.descText} property`)
  const [isRegularSize, setIsRegularSize] = useState(isBiggerThanWindowSize(screenSizes.md));
  const [imgSet, setImgSet] = useState<string[]>([])

  useEffect(() => {

    function windowResize() {
      setIsRegularSize(isBiggerThanWindowSize(screenSizes.md))

      const isSmall = isSmallerThanWindowSize(screenSizes.md)
      const isXl = isBiggerThanWindowSize(screenSizes.xl)

      if(isSmall) {
        setImgSet(props.imgsSm)
      } else if (isXl) {
        setImgSet(props.imgsXl)
      } else {
        setImgSet(props.imgsMd)
      }
    }

    windowResize()
    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [props.imgsMd, props.imgsSm, props.imgsXl])

  function renderRegSlides() {
    return (
      <Swiper 
      modules={[Pagination, Navigation, EffectCreative]} 
      pagination={{
        clickable: true,
        horizontalClass: 'prop-swiper-pagination',
        bulletClass: 'prop-swiper-pagination-bullets',
        bulletActiveClass: 'prop-swiper-pagination-bullet-active'
      }}
      navigation = {{
        nextEl: '.custom-swiper-button-next',
      }}
      loop={true}
      onInit={function (swiper) {
        swiper.slideTo(1)
      }}
      className='prop-swiper'>
      {
        imgSet.map((img, index) => {
          return (
            <SwiperSlide key={index} id='props-control'>
              <LazyLoadImage effect='blur'
                  placeholderSrc={props.imgsPh[index]}
                  src={img} alt=''/>
            </SwiperSlide>
          )
        })
      }
    <div className='custom-swiper-button-next' tabIndex={0} role='button' aria-controls='props-control'>
      <img src='/icons/slider-right-arrow.svg' alt='right slider arrow'/>
    </div>
    </Swiper>
    )
  }

  function renderSlidesSmall() {
    return (
      <Swiper 
      modules={[Pagination, Navigation, EffectCreative]} 
      pagination={{
        clickable: true,
        horizontalClass: 'prop-swiper-pagination',
        bulletClass: 'prop-swiper-pagination-bullets',
        bulletActiveClass: 'prop-swiper-pagination-bullet-active'
      }}
      navigation = {{
        nextEl: '.custom-swiper-button-next',
        prevEl: '.custom-swiper-button-prev',
      }}
      className='prop-swiper'>
      {
        imgSet.map((img, index) => {
          return (
            <SwiperSlide id='props-control' className='prop-swiper-slide' key={index}>
                <LazyLoadImage effect='blur'
                  placeholderSrc={props.imgsPh[index]}
                  src={img} alt=''/>
            </SwiperSlide>
          )
        })
      }
    <div className='custom-swiper-button-next' role='tab' tabIndex={0} aria-controls='props-control'>
      <img src='/icons/slider-right-arrow.svg' alt='right slider arrow'/>
    </div>
    <div className='custom-swiper-button-prev' role='tab' tabIndex={0} aria-controls='props-control'>
      <img src='/icons/slider-left-arrow.svg' alt='left slider arrow'/>
    </div>
    </Swiper>
    )
  }

  function renderSmall() {
    return (
      <>
        <div className='px-3'>
          <BackArrowLink 
              className='prop-page-back-arrow' 
              txt={ t('props-page.back-arrow') }
              link={ Paths.properties.index }
              color='var(--ies-secondary)'
          />
          <h1 className='pt-6 mb-0'>{ props.descTitle }</h1>
          <p className='mb-2' style={{'fontWeight': 600}}>{ props.subTitle }</p>
        </div>
        <div className='prop-page-content-container px-3 py-4'>
          <div className='prop-page-desc pb-2'>
              { props.descText.map((p, _) => 
              <> 
                <p> 
                  { p } 
                </p> 
                <p className='mt-2'/>
              </>) }
          </div>
          { renderSlidesSmall() }
        </div>
    </>
    )
  }

  function renderReg() {
    return (
      <>
      <div className='prop-page-content-container'>
        <BackArrowLink 
            className='prop-page-back-arrow' 
            txt={ t('props-page.back-arrow') }
            link={ Paths.properties.index }
            color='var(--ies-white)'
        />
        <div className='prop-page-desc-container'>
          <div className='prop-page-desc'>
            <h1 className='mb-0'>{ props.descTitle }</h1>
            <p className='mb-3' style={{'fontWeight': 600}}>{ props.subTitle }</p>
            { props.descText.map((p, _) => 
            <> 
              <p> 
                { p } 
              </p> 
              <p className='mt-2'/>
            </>) }
          </div>
        </div>
        <img src={imgSet[0]} alt=''/>
      </div>
      { renderRegSlides() }
    </>
    )
  }

  return (
    <div className='prop-page'>
      { isRegularSize ?
        renderReg() :
        renderSmall() }
    </div>
  );
}

export default PropPage