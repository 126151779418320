import { breakpointsPx } from './consts'

export function isBiggerThanWindowSize(screenSize) {
    if (window === "undefined") {
        return true
    }

    return breakpointsPx[screenSize] <= window.innerWidth;
}

export function isSmallerThanWindowSize(screenSize) {
    if (window === "undefined") {
        return true
    }

    return window.innerWidth <= breakpointsPx[screenSize];
}

export function isSmallerThanSize(pxSize) {
    if (window === "undefined") {
        return true
    }

    return window.innerWidth <= pxSize;
}