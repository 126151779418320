import React from 'react';

export type ImageCardMaskProps = {
  gradient?: boolean
}

function ImageCardMask(props: ImageCardMaskProps) {
  return (
    <div className='img-card-mask' data-gradient={props.gradient ?? false}></div>
  )
}

export default ImageCardMask;