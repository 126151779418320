import { useEffect } from 'react';

export function useNavBarColor(color: string) {
    useEffect(() => {
        let navbar = document.getElementById('nav-bar')!
        const prevColor = navbar.style.backgroundColor
        navbar.style.backgroundColor = color
        return () => {
            navbar.style.backgroundColor = prevColor
        }
    })
}