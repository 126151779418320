import React  from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export type LazyLoadBgImgProps = {
    src: string
    placeholder: string
    center: boolean
}

function LazyLoadBgImg(props: LazyLoadBgImgProps) {
const className = props.center ? 'bg-img': 'bg-img-b'
 return (
    <LazyLoadImage wrapperClassName={className} effect='blur' 
    className={className} placeholderSrc={props.placeholder}
    visibleByDefault={true}
     src={props.src} alt=''/>
 )
}

export default LazyLoadBgImg