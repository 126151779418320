import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ShareholdersSection.scss';
import { screenSizes } from '../../../utils/consts';
import { isBiggerThanWindowSize } from '../../../utils/windowSize';
import DecoHeader from '../../../core-ui/DecoHeader/DecHeader';
import { useInView } from 'react-intersection-observer';

type ImgDesc = {
  img: string
  paddingB?: string
}

function ShareholdersSection() {
  const { t } = useTranslation()
  const { ref, inView } = useInView({
    triggerOnce: true,
    delay: 100,
    //threshold: 0,
    trackVisibility: true
  });
  const [isXLSize, setIsXLSize] = useState(isBiggerThanWindowSize(screenSizes.lg));

  useEffect(() => {

    function windowResize() {
      setIsXLSize(isBiggerThanWindowSize(screenSizes.lg))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])
  
  const images = [
    {
      img: '/images/shareholders/GEYERFamily.jpg'
    },
    {
      img: '/images/shareholders/logoBlackDFORMS.jpg'
    },
    {
      img: '/images/shareholders/MenoraMivtachimLogo.jpg'
    },
    {
      img: '/images/shareholders/clal.jpg'
    },
    {
      img: '/images/shareholders/FnxLogo.jpg',
      paddingB: '0.5rem'
    }
  ]

  function renderBoxImage(img: ImgDesc) {
    const style = img.paddingB ? {paddingBottom: img.paddingB} : undefined
    return (
      <div key={img.img} className='shareholders-section-img-box' shareholders-in-view={inView.toString()}>
        <img style={style} 
          src={img.img} alt={`shareholder ${img.img}`}/>
      </div>
    )
  }

  function renderXL() {
    return (
      <div className='shareholders-section-images' shareholders-in-view={inView.toString()}>
        { images.map(img => renderBoxImage(img)) }
      </div>
    )
  }
  
  function renderRegular() {
    const listOne = images.slice(0, 3)
    const listTwo = images.slice(3, 5)

    return (
      <>
      <div className='shareholders-section-images' shareholders-in-view={inView.toString()}>
        { listOne.map(img => renderBoxImage(img)) }
      </div>
      <div className='shareholders-section-images' shareholders-in-view={inView.toString()}>
        { listTwo.map(img => renderBoxImage(img)) }
      </div>
      </>
    )
  }

  return (
    <div ref={ref} className='shareholders-section'>
      <DecoHeader text={ t('home.shareholders.header') } />
      { isXLSize ?
         renderXL() :
         renderRegular()
      }
    </div>
  )

}

export default ShareholdersSection;
