export const screenSizes = {
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
}

export const breakpointsPx = {
    0: 0,
    1: 380,
    2: 768,
    3: 960,
    4: 1440,
}