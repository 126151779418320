import React from 'react';
import { useTranslation } from 'react-i18next';
import PropPage from '../property/PropPage';

function PalmahimPage() {
  const { t } = useTranslation()
  
  return (
    <PropPage 
    descTitle={ t("props.pal.title") }
    subTitle={ t("props.pal.sub-title") }
    descText={[
      t("props.pal.desc-p1"),
      t("props.pal.desc-p2"),
    ]}
    imgsSm={[
      '/images/props/pal/img1-sm.jpg',
      '/images/props/pal/img2-sm.jpg',
      '/images/props/pal/img3-sm.jpg',
      '/images/props/pal/img4-sm.jpg',
      '/images/props/pal/img5-sm.jpg',
    ]}
    imgsMd={[
      '/images/props/pal/img1.jpg',
      '/images/props/pal/img2.jpg',
      '/images/props/pal/img3.jpg',
      '/images/props/pal/img4.jpg',
      '/images/props/pal/img5.jpg',
    ]}
    imgsXl={[
      '/images/props/pal/img1.jpg',
      '/images/props/pal/img2.jpg',
      '/images/props/pal/img3.jpg',
      '/images/props/pal/img4.jpg',
      '/images/props/pal/img5.jpg',
    ]}
    imgsPh={[
      '/images/props/pal/img1-px.jpg',
      '/images/props/pal/img2-px.jpg',
      '/images/props/pal/img3-px.jpg',
      '/images/props/pal/img4-px.jpg',
      '/images/props/pal/img5-px.jpg',
    ]}
    />
  );
}

export default PalmahimPage;
