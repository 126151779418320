import React from 'react';
import { Outlet } from 'react-router-dom';
import Fotter from '../fotter/Fotter';
import NavBar from '../navbar/Navbar';
import './LayoutOutlet.scss';

function LayoutOutlet() {
  return (
    <>
      <NavBar/>
      <Outlet/>
      <Fotter/>
    </>
  );
}

export default LayoutOutlet;