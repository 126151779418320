import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import NotFoundPage from './components/404/NotFoundPage';
import ContactUsPage from './components/contactUs/ContactUsPage';
import HomePage from './components/home/HomePage';
import LayoutOutlet from './components/layout/LayoutOutlet';
import PropsPage from './components/properties/PropsPage';
import AirparkPage from './components/properties/propsPages/AirparkPage';
import PalmahimPage from './components/properties/propsPages/PalmahimPage';
import TeamPage from './components/team/TeamPage';
import { Paths } from './routing/paths';
import ScrollToTop from './routing/scrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
      <Routes>
          <Route path='' element={<LayoutOutlet/>} >
            <Route path={ Paths.home } element={ <HomePage/> }></Route>
            <Route path={ Paths.team } element={ <TeamPage/> }></Route>
            <Route path={ Paths.contact } element={ <ContactUsPage/> }></Route>
            <Route path={ Paths.properties.index }>
              <Route path='palmahim' element={ <PalmahimPage/> }/>
              <Route path='airpark' element={ <AirparkPage/> }/>
              <Route index element={ <PropsPage/> }/>
            </Route>
          </Route>
          <Route path='*' element={ <NotFoundPage/> }></Route>
      </Routes>
      </ScrollToTop>

    </BrowserRouter>
  );
}

export default App;
