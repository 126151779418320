import React from 'react';
import IconDetailed from './IconDetailed';

type PhoneProps = {
  fontWeight?: number
}

function Phone(props: PhoneProps) {
  return (
    <IconDetailed 
      img='/icons/tele-white.svg'
      text='+972-3-7133222'
      fontWeight={props.fontWeight}
    />
  )
}

export default Phone;