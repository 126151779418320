import React, { useEffect, useState } from 'react';
import './TeamSection.scss';
import { useTranslation } from 'react-i18next';
import ArrowLink from '../../../core-ui/arrowLink/ArrowLink';
import { screenSizes } from '../../../utils/consts';
import { isBiggerThanWindowSize } from '../../../utils/windowSize';
import { Paths } from '../../../routing/paths';

function TeamSection() {
  const { t } = useTranslation()
  const [isRegularSize, setIsRegularSize] = useState(isBiggerThanWindowSize(screenSizes.md));
  useEffect(() => {

    function windowResize() {
      setIsRegularSize(isBiggerThanWindowSize(screenSizes.md))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  function renderSmallView() {
    return (
        <div className='team-section--sm'>
          <div className='position-relative'>
            <img src='/images/team/team-md.jpg' alt='the ies team'/>
            <div className='team-section-img-overlay'/>
          </div> 
          <div className='home-team-section-content-container'>
            <div className='home-team-section-content'>
                <h1>{ t("home.team.header") } </h1>
                <ArrowLink 
                  color='var(--ies-white)'
                  txt={ t("home.team.link") }
                  link={ Paths.team }
                  />
            </div>
          </div>
        </div>
    )
  }

  function renderRegularView() {
    const headers = t("home.team.header").split(' ').map((text, index) => <h1 key={index}>{text}</h1>)
    return (
        <div className='home-team-section--reg '>
            <img className='gallery-img' src='images/team/team-md.jpg' alt='the ies team'/>
            <div className='home-team-section-content-container'>
              <div className='home-team-section-content'>
                {headers}
                <ArrowLink 
                  color='var(--ies-white)'
                  txt={ t("home.team.link") }
                  link={ Paths.team }
                  />
                </div>
              </div>
        </div>  
    )
  }

  return isRegularSize ?
    renderRegularView() :
    renderSmallView()
}

export default TeamSection;
