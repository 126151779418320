import React from 'react';
import './HomePage.scss';
import WelcomeSection from './welcomeSection/WelcomeSection';
import PropSection from './propSection/PropSection';
import TeamSection from './teamSection/TeamSection';
import { useNavBarColor } from '../navbar/navBarUtils';
import ShareholdersSection from './shareholders/ShareholdersSection';
import ClientsSection from './clients/ClientsSection';

function HomePage() {
  useNavBarColor("#bfe7ff")

  return (
  <>
    <WelcomeSection/>
    <PropSection/>
    <ShareholdersSection/>
    <TeamSection/>
    <ClientsSection/>
  </>
  )
}

export default HomePage;
