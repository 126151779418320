import React from 'react';
import { useTranslation } from 'react-i18next';
import PropPage from '../property/PropPage';

function AirparkPage() {
  const { t } = useTranslation()

  return (
    <PropPage 
      descTitle={ t("props.airpark.title") }
      subTitle={ t("props.airpark.sub-title") }
      descText={[
        t("props.airpark.desc-p1"),
        t("props.airpark.desc-p2"),
      ]}
      imgsSm={[
        '/images/props/airpark/img1-sm.jpg',
        '/images/props/airpark/img2-sm.jpg',
        '/images/props/airpark/img3-sm.jpg',
        '/images/props/airpark/img4-sm.jpg',
      ]}
      imgsMd={[
        '/images/props/airpark/img1-md.jpg',
        '/images/props/airpark/img2-md.jpg',
        '/images/props/airpark/img3-md.jpg',
        '/images/props/airpark/img4-md.jpg',
      ]}
      imgsXl={[
        '/images/props/airpark/img1.jpg',
        '/images/props/airpark/img2.jpg',
        '/images/props/airpark/img3.jpg',
        '/images/props/airpark/img4.jpg',
      ]}
      imgsPh={[
        '/images/props/airpark/img1-px.jpg',
        '/images/props/airpark/img2-px.jpg',
        '/images/props/airpark/img3-px.jpg',
        '/images/props/airpark/img4-px.jpg',
      ]}
    />
  );
}

export default AirparkPage;
