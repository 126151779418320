import React, { useEffect, useState } from 'react';
import { screenSizes } from '../../utils/consts';
import { isBiggerThanWindowSize } from '../../utils/windowSize';
import './Fotter.scss';

function Fotter() {
  const [isRegularSize, setIsRegularSize] = useState(isBiggerThanWindowSize(screenSizes.md));

  useEffect(() => {

    function windowResize() {
      setIsRegularSize(isBiggerThanWindowSize(screenSizes.md))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  function renderRegularSize() {
    return (
      <div className='fotter-content'>
        <div className='d-flex align-items-center' style={{"gap": "1.75rem"}}>
          <img src="/icons/logo.svg" alt="ies logo" style={{"width": "92px", "height": "34px"}}/>
          <div>
            <img src="/icons/mail.svg" alt="mail logo"/>
            <a href='mailto:office@ies.com' target="_blank" rel="noopener noreferrer" className='ps-2'>office@ies.com</a>
          </div>
          <div>
            <img src="/icons/tele.svg" alt="telepone logo"/>
            <span className='ps-2'>+972-3-7133222</span>
          </div>
        </div>
        <div className='flex-spacer'></div>
        <small className='align-self-end'>© IES Holdings LTD.</small>
      </div>
    )
  }

  function renderSmallSize() {
    return (
      <div className='fotter-content--sm'>
        <img src="/icons/logo.svg" alt="ies logo" style={{"width": "92px", "height": "34px"}}/>
        <div className='d-flex align-items-center flex-wrap' style={{"gap": "1.75rem"}}>
          <div>
            <img src="/icons/mail.svg" alt="mail logo"/>
            <a href='mailto:office@ies.com' target="_blank" rel="noopener noreferrer" className='ps-2'>office@ies.com</a>
          </div>
          <div>
            <img src="/icons/tele.svg" alt="telephone logo"/>
            <span className='ps-2'>+972-3-7133222</span>
          </div>
        </div>
        <small>© IES Holdings LTD.</small>
      </div>
    )
  }

  return (
    <div className='fotter'>
      { isRegularSize ? renderRegularSize() : renderSmallSize() }
    </div>
  )
}

export default Fotter;