import React, { useEffect, useState } from 'react';
import './PropSection.scss';
import { useTranslation } from 'react-i18next';
import ArrowLink from '../../../core-ui/arrowLink/ArrowLink';
import { Paths } from '../../../routing/paths';
import ImageCardMask from '../../../core-ui/imageCard/ImageCardMask';
import { useNavigate } from 'react-router-dom';
import ImageCardMaskTitle from '../../../core-ui/imageCard/ImageCardTitle';
import ImageCard from '../../../core-ui/imageCard/ImageCard';
import ImageCardImg from '../../../core-ui/imageCard/ImageCardImg';
import { screenSizes } from '../../../utils/consts';
import { isBiggerThanWindowSize, isSmallerThanWindowSize } from '../../../utils/windowSize';

function PropSection() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isSmallSize, setIsSmallSize] = useState(isSmallerThanWindowSize(screenSizes.md));
  const [finDevImg, setFinDevImg] = useState('/images/financial_XL.png')

  useEffect(() => {
    function windowResize() {
      const isSmall = isSmallerThanWindowSize(screenSizes.md)
      const isXl = isBiggerThanWindowSize(screenSizes.xl)

      setIsSmallSize(isSmall)

      if(isSmall) {
        setFinDevImg('/images/financial_XL.png')
      } else if (isXl) {
        setFinDevImg('/images/financial_XS.png')
      } else {
        setFinDevImg('/images/financial_MD.png')
      }
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  return (
    <div className='home-prop-section'>
      <div className='container py-6'>
        <div className='home-prop-section-head'>
          <h2>{ t("home.props.head.header") }</h2>
          <ArrowLink link={ Paths.properties.index } txt={ t("home.props.head.link") }/>
        </div>

        <div className='home-prop-section-grid'>
          <figure 
            onClick={() => navigate(Paths.properties.palmahim)}
            className='home-prop-section-grid-item--1 position-relative'>
            <ImageCard link={Paths.properties.airpark}>
              <ImageCardImg className='grid-img' src='/images/props/homepage/main-props-pal.jpg' alt='palmahim property'/>
              <ImageCardMaskTitle title={ t("home.props.img-headers.pal") }/>
              <ImageCardMask gradient={true}/>
            </ImageCard>
          </figure>
          <figure 
            className='home-prop-section-grid-item--2 position-relative'>
            <ImageCard>
              <ImageCardImg className='grid-img' src={finDevImg} alt='palmahim property'/>
              <ImageCardMaskTitle title={ t("home.props.img-headers.financial") }/>
            </ImageCard>
          </figure>
          <figure 
            className='home-prop-section-grid-item--3 position-relative'>
            <ImageCard>
              <ImageCardImg className='grid-img' src='/images/props/homepage/estate.png' alt='airpark property'/>
              <ImageCardMaskTitle title={ t("home.props.img-headers.real-estate") } splitNewLine={isSmallSize}/>
            </ImageCard>
          </figure>
          <figure
            onClick={() => navigate(Paths.properties.airpark)}
            className='home-prop-section-grid-item--4 position-relative'>
            <ImageCard link={Paths.properties.airpark}>
              <ImageCardImg src='/images/airparkvis.png' alt='airpark property'/>
              <ImageCardMaskTitle title={ t("home.props.img-headers.airpark") }/>
              <ImageCardMask gradient={true}/>
            </ImageCard>
          </figure>
        </div>
      </div>
    </div>
  );
}

export default PropSection;
