import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.scss';
import { Paths } from '../../routing/paths';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function NavBar() {
  const [moblieToggleVisible, setMoblieToggleVisible] = useState(false)
  const { t } = useTranslation()

  
  function mobileToggleClick() {
    setMoblieToggleVisible(!moblieToggleVisible)
  }

  function closeMobileToggleClick() {
    setMoblieToggleVisible(false)
  }

  function navLinkClasses(isActive: boolean): string {
    return isActive ? 'nav-link nav-link--active': 'nav-link'
  }

  return (
    <nav id='nav-bar' className='nav-bar'>
      <div className='nav-bar-logo d-flex align-items-center'>
        <Link to={ Paths.home }>
          <LazyLoadImage src='/icons/logo.svg' alt='IES Holdings'/>
        </Link>
      </div>

      <button className='moblie-nav-toggle' aria-controls='nav-bar-links' aria-expanded={moblieToggleVisible}
        onClick={mobileToggleClick}>
          <span className='sr-only'>{ t("navbar.home") }</span>
      </button>

      <div id='nav-bar-links' className='nav-bar-links' data-visible={moblieToggleVisible}>
        <NavLink to={ Paths.home } 
          className={({ isActive }) => navLinkClasses(isActive)}
          onClick={closeMobileToggleClick}>
            { t("navbar.home") }
        </NavLink>
        <NavLink to={ Paths.team } 
          className={({ isActive }) => navLinkClasses(isActive)}
          onClick={closeMobileToggleClick}>
            { t("navbar.team") }
        </NavLink>
        <NavLink to={ Paths.properties.index } 
          className={({ isActive }) => navLinkClasses(isActive)}
          onClick={closeMobileToggleClick}>
            { t("navbar.properties") }
        </NavLink>
        <NavLink to={ Paths.contact } 
          className={({ isActive }) => navLinkClasses(isActive)}
          onClick={closeMobileToggleClick}>
            { t("navbar.contact") }
        </NavLink>
      </div>
    </nav>
  )
}

export default NavBar;