import React, { useEffect, useState } from 'react';
import './WelcomeSection.scss';
import { useTranslation } from 'react-i18next';
import { screenSizes } from '../../../utils/consts';
import { isBiggerThanWindowSize, isSmallerThanSize } from '../../../utils/windowSize';
import { Button } from 'react-bootstrap';
import LazyLoadBgImg from '../../../core-ui/LazyLoadBgImg/LazyLoadBgImg';

function WelcomeSection() {
  const { t } = useTranslation()
  const [renderSmallImg, setRenderSmallImg] = useState(isSmallerThanSize(500));
  const [isRegularSize, setIsRegularSize] = useState(isBiggerThanWindowSize(screenSizes.md));

  useEffect(() => {

    function windowResize() {
      setRenderSmallImg(isSmallerThanSize(500))
      setIsRegularSize(isBiggerThanWindowSize(screenSizes.md))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  function renderContentRegualr() {
    return (
      <>
        <h1>{t('home.welcome.regular.header')}</h1>
        <h2>{t('home.welcome.regular.subtitle')}</h2>
      </>
    )
  }

  function renderContentSmall() {
    return (
      <>
        <h1>{t('home.welcome.small.header-l1')}</h1>
        <h1>{t('home.welcome.small.header-l2')}</h1>
        <h2>{t('home.welcome.small.subtitle-l1')}</h2>
        <h2>{t('home.welcome.small.subtitle-l2')}</h2>
      </>
    )
  }

  return (
    <div className='welcome-section-container position-relative'>
      <LazyLoadBgImg 
          src={
            renderSmallImg ?
              '/images/main-header-sm.jpg'
              : '/images/main-header-xl.jpg'
          }
          placeholder='/images/main-header-px.jpg'
          center={false} />
      <div className='container welcome-section-content'>
        { isRegularSize ?
            renderContentRegualr() :
            renderContentSmall ()
        }
        <Button onClick={() => {window.open('https://maya.tase.co.il/en/company/431?view=details')}} className='mt-3'>{t('home.welcome.btn')}</Button>
      </div>
    </div>
  );
}

export default WelcomeSection;
