import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export type ImageCardImgProps = {
  src: string
  className?: string
  alt?: string
}

function ImageCardImg(props: ImageCardImgProps) {
  return (
    <LazyLoadImage wrapperClassName={'img-card-img ' + props.className ?? ''} 
      effect="opacity" visibleByDefault={true}
      className={'img-card-img ' + props.className ?? ''}
      src={props.src} alt={props.alt}/>
  )
}

export default ImageCardImg;