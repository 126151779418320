const propertiesBasePath = '/properties'

export const Paths = {
    home: '/',
    team: '/team',
    properties: {
        index: '/properties',
        palmahim: `${propertiesBasePath}/palmahim`,
        airpark: `${propertiesBasePath}/airpark`,
    },
    contact: '/contact',
}