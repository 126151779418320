// ScrollToTop.jsx
import { useEffect } from "react";
import { useLocation } from "react-router";

export type ScrollToTopProps = {
    children: JSX.Element | JSX.Element[]
}

function ScrollToTop(props: ScrollToTopProps) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      { props.children }
    </>
  )
};

export default ScrollToTop;