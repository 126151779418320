import React from 'react';
import './DecHeader.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export type DecoHeaderProps = {
    text: string
}

function DecoHeader(props: DecoHeaderProps) {
  return (
    <div className='deco-header'>
        <LazyLoadImage className='deco-header-ellipse' src='/icons/ellipseLeft.svg' alt='decoration'/>
        <h1>{ props.text }</h1>
        <LazyLoadImage className='deco-header-ellipse' src='/icons/ellipseRight.svg' alt='decoration'/>
    </div>
  )
}

export default DecoHeader;