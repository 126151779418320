import React from 'react';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../utils/scroll';
import './ArrowLink.scss';

export type ArrowLinkProps = {
    txt: string,
    link?: string,
    className?: string
    color?: string
}

function ArrowLink(props: ArrowLinkProps) {
  const color = props.color ?? 'var(--ies-primary)'

  return (
    <Link to={props.link ?? "#"} 
      className={'arrow-link '.concat(props.className ?? "")}
      onClick={scrollToTop}>
        <p style={{color: color}}>
          { props.txt }
        </p>
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.71484 10.5H14.2863" stroke={color} strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12.1426 8.35742L14.2854 10.5003L12.1426 12.6431" stroke={color} strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Link>
  )
}

export default ArrowLink;