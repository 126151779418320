import React from 'react';
import './NotFoundPage.scss';
import { useTranslation } from 'react-i18next';

function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <div>
        <h1>{ t('404') }</h1>
    </div>
  );
}

export default NotFoundPage;
