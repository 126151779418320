import React from 'react';

export type ImageCardContentProps = {
  title: string,
  content: string,
}

function ImageCardContent(props: ImageCardContentProps) {
  return (
    <div className='img-card-content'>
      <h2>{ props.title }</h2>
      <p>{ props.content }</p>
    </div>
  )
}

export default ImageCardContent;